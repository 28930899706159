const img = "./images/icons";

export const navLinks = [
  {
    path: "/",
    text: "Home",
    img: `${img}/home.svg`,
  },
  {
    path: "/about",
    text: "About Us",
    img: `${img}/about.svg`,
  },
  {
    path: "/islamiyya",
    text: "Islamiyyah",
    img: `${img}/islamiyya.svg`,
  },
  {
    path: "/programs",
    text: "Programs",
    img: `${img}/programs.svg`,
  },
  // {
  //   path: "/post",
  //   text: "Posts",
  //   img: `${img}/post.svg`,
  // },
  // {
  //   path: "/contact",
  //   text: "Contact",
  //   img: `${img}/contact.svg`,
  // },
  // {
  //   path: "/books",
  //   text: "Books",
  //   img: `${img}/books.svg`,
  // },
];
